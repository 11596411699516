.ck-editor__editable {
  min-height: 100px;
}
/* .ck-body-wrapper{
  display: flex;
  margin-top: 440px;
  z-index: 1200;
  flex-shrink: 0;
  align-items:center;
  justify-content: center
} */
