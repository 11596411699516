.menu-account{
    position: absolute;
   margin-left: 88%;
   float: left;
  }
  .arrow{
    width: 10px;
  }
  .selected{
    background-color: #601124;
    color: white;
  }